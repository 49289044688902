// import { StatsGrid } from "../Components/StatsGrid";
import SearchBox from "../../Components/SearchBox";
import { ActionBar } from "../../Components/ActionBar";
import SideOverlay from "../../Components/Overlays/SideOverlay";
import { useEffect, useState } from "react";
import { ParnterScreenFields } from "../Client/PartnerScreen";
import {
  getTasks,
  getPinned,
  getMissedInvoices,
  getSystemNotif,
  getStateClients,
} from "../../functions/api";
import TaskList, { TaskItem } from "../../Components/TaskLine";
import TaskOverlay from "../../Components/Overlays/TaskOverlay";
import { CallButton } from "../../Components/CallButton";
import { PinBar } from "../../Components/PinComponents";
import { Alert, Card, Switch, Title } from "@mantine/core";
import InvoiceCard from "../../Components/InvoiceCard";
import { AlertCircle } from "tabler-icons-react";
import { SourceBadge } from "../../Components/Inputs/EditableMulti";
import TaskMap from "../../Components/TaskMap";
import { FormattedMessage, useIntl } from 'react-intl';

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ScrumBoard = ({ data }) => {
  const [items, setItems] = useState(data);

  const handleDrop = (index, activityStatus) => {
    const updatedItems = [...items];
    updatedItems[index].client_activity_status = activityStatus;
    setItems(updatedItems);
  };

  const ActivityStatusColumn = ({ status, title }) => {
    const [{ isOver }, dropRef] = useDrop({
      accept: "item",
      drop: () => handleDrop(status),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    });

    return (
      <Card
        withBorder
        shadow="sm"
        ref={dropRef}
        style={{
          border: "1px solid #ccc",
          padding: "10px",
          //maxHeight: "200px",
          width: "250px",
          margin: 5,
          backgroundColor: isOver ? "#f0f0f0" : "#ffffff",
        }}
      >
        <Title style={{ margin: 5, paddingBottom: 10 }} order={3}>
          {title}
        </Title>
        {items.map((item, index) => {
          if (item.client_activity_status === status) {
            return <DCard key={index} item={item} index={index} />;
          }
          return null;
        })}
      </Card>
    );
  };

  const DCard = ({ item, index }) => {
    const [{ isDragging }, dragRef] = useDrag({
      type: "item",
      item: { index },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });

    return (
      <Card
        withBorder
        shadow="sm"
        ref={dragRef}
        style={{
          border: "1px solid #ccc",
          padding: "10px",
          marginBottom: "10px",
          backgroundColor: isDragging ? "#f0f0f0" : "#ffffff",
          cursor: "move",
        }}
      >
        {item.c_name.replace(/\d/g, '')}
        <div style={{fontSize: 12, color: "grey"}}>
          {item.c_phone}
        </div>
      </Card>
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        style={{
          marginTop: 5,
          marginBottom: 20,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <ActivityStatusColumn title="Gaida saziņu" status="1" />
        <ActivityStatusColumn title="Gaida piedāvājumu" status="2" />
        <ActivityStatusColumn title="Gaida uzstādīšanu" status="3" />
        <ActivityStatusColumn title="Pabeigts" status="4" />
        {/* Add more columns here as needed */}
      </div>
    </DndProvider>
  );
};

export const additionalDisplay = (displayMore) => {
  return {
    showCheckbox: displayMore,
    showAsignedTo: displayMore,
    showCreatedBy: displayMore,
    showCreatedOn: displayMore,
    showDeadline: displayMore,
  };
};

export default function HomeScreen() {
  const intl = useIntl();
  const [sidemodal, setSidemodal] = useState(false);
  const [modalData, setModaldata] = useState();
  const [showStates, setShowStates] = useState(true);
  const [stateclients, setStateClients] = useState([]);

  const [upcomingTasks, setUpcomingTasks] = useState([]);
  const [missedTasks, setMissedTasks] = useState([]);
  const [overlay, setOverlay] = useState(false);
  const [ovData, setOvdata] = useState();
  const [pinned, setPinned] = useState();
  const [invoices, setInvoices] = useState();
  const [sysnotif, setSysnotif] = useState();

  const taskOverlay = (data) => {
    setOvdata(data);
    setOverlay(true);
  };

  const displayMore = true;

  const handleAction = (i, type) => {
    const displayMore = false;
    i =
      type == "task"
        ? {
          ...i,
          component: (
            <>
              <ParnterScreenFields data={i} />
              {i.sources &&
                i.sources.map((cs) => cs && <SourceBadge cs={cs} />)}
              <div style={{ marginTop: 20 }}>
                <CallButton client={i} />
              </div>
              <div
                style={{ marginTop: 20 }}
                className="bg-white rounded-md shadow-lg"
              >
                {i && (i.task_name || i.task_description) && (
                  <TaskItem
                    onClick={() => taskOverlay(i)}
                    task={i}
                    displayData={additionalDisplay(displayMore)}
                  />
                )}
              </div>
            </>
          ),
          route: i.clientid ? "/partner/" + i.clientid : null,
        }
        : i;
    setModaldata(i);
    setSidemodal(true);
  };

  useEffect(() => {
    getStateClients().then((x) => {
      setStateClients(x);
      console.log(x);
    });
  }, []);

  useEffect(() => {
    const loadScreen = () => {
      const now = new Date();
      getTasks().then((tasks) => {
        const upcomingTasks = tasks.filter(
          (task) => new Date(task.deadline) >= now
        );
        const missedTasks = tasks.filter(
          (task) => new Date(task.deadline) < now
        );
        setUpcomingTasks(upcomingTasks);
        setMissedTasks(missedTasks);
      });
      getPinned().then((pins) => {
        setPinned(pins);
      });
      getMissedInvoices().then((inv) => setInvoices(inv));
    };

    loadScreen();

    const interval = setInterval(() => {
      loadScreen();
    }, 60000);

    return () => clearInterval(interval);
  }, [sidemodal, overlay, ovData, modalData]);

  useEffect(() => {
    getSystemNotif().then((x) => {
      setSysnotif(x);
    });
  }, []);

  return (
    <>
      <div style={{ maxHeight: 200, overflow: "auto" }}>
        {sysnotif &&
          sysnotif.length > 0 &&
          sysnotif.map((n) => (
            <Alert
              icon={<AlertCircle size="2rem" />}
              title={n.title}
              color={n.color ? n.color : "green"}
              style={{ margin: 10, marginBottom: 5 }}
            >
              {n.text}
            </Alert>
          ))}
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <TaskOverlay
          open={overlay}
          setOpen={setOverlay}
          data={ovData}
          callBack={setSidemodal}
        />
        <SideOverlay open={sidemodal} setOpen={setSidemodal} data={modalData} />
        {/* <StatsGrid /> */}


        <div
          style={{
            //flex: "100%",
            width: "100%",
            display: "flex",
            // padding: 10,
            padding: 5,
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <div style={{width: "100%"}}>
            <div style={{ width: "100%", maxWidth: 500 }}>
              <SearchBox onClick={(data) => handleAction(data, "task")} />
            </div>
          </div>
          {/* <Switch label="Rādīt klientu statusus" checked={showStates} onChange={(event) => setShowStates(event.currentTarget.checked)} /> */}
        </div>

        {/* {showStates && stateclients && stateclients.length > 0 && (
          <ScrumBoard data={stateclients} />
        )} */}

        {/* <div
          style={{
            flex: "100%",
            padding: 10,
            flexDirection: "row",
          }}
        >
          <div>
            <div style={{ maxWidth: 500 }}>
              <SearchBox onClick={(data) => handleAction(data, "task")} />
            </div>
          </div>
        </div> */}
        {pinned && pinned.length > 0 && <PinBar pinned={pinned} />}
        {(upcomingTasks.length > 0 || missedTasks.length > 0) && (
          <TaskMap
            tasks={[...upcomingTasks, ...missedTasks]}
            callBack={(data) => handleAction(data, "task")}
          />
        )}
        {upcomingTasks.length > 0 && (
          <TaskList
            title={intl.formatMessage({ id: "upcoming_tasks" })}
            tasks={upcomingTasks}
            displayData={additionalDisplay(displayMore)}
            onClick={(data) => handleAction(data, "task")}
          />
        )}

        {missedTasks.length > 0 && (
          <TaskList
            title={intl.formatMessage({ id: "delayed_tasks" })}
            tasks={missedTasks}
            displayData={additionalDisplay(displayMore)}
            onClick={(data) => handleAction(data, "task")}
          />
        )}
        <div className="px-4 py-6 sm:px-0" style={{ flex: 4, flexBasis: 300 }}>
          <ActionBar action={handleAction} />
          {invoices && invoices.length > 0 && (
            <div style={{ marginTop: 20 }}>
              <span style={{ fontSize: 15, fontWeight: "bold" }}>
                <FormattedMessage id="missed_invoices" />
              </span>
              <Card
                withBorder
                radius="md"
                style={{
                  maxHeight: 400,
                  overflow: "hidden",
                  overflowY: "auto",
                  padding: 5,
                  margin: 5,
                }}
              >
                {invoices &&
                  invoices.map((inv) => (
                    <InvoiceCard key={inv.invoice_id} invoice={inv} />
                  ))}
              </Card>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

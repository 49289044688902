import React from "react";
import { useMantineTheme, Text } from "@mantine/core";
import { Check, Square } from "tabler-icons-react";
import { timeDiffToDisplay } from "../functions/misc";
import OpenInGoogleMaps from "./OpenInGoogleMaps";
import { FormattedMessage } from 'react-intl';

const TaskList = ({ title, tasks, displayData, onClick }) => {
  const theme = useMantineTheme();

  return (
    <div style={{ padding: 10, paddingTop: 25, flex: 600, minWidth: 350 }}>
      <Text weight={700} size="md" style={{ padding: 5 }}>
        {title && title}
      </Text>
      <div className="bg-white rounded-md shadow-lg">
        {tasks.map((task, index) => (
          <TaskItem
            task={task}
            index={task.taskid}
            key={index}
            displayData={displayData}
            onClick={onClick}
          />
        ))}
      </div>
    </div>
  );
};

export const TaskItem = ({ task, index, displayData, onClick }) => {
  const {
    showCheckbox,
    showAsignedTo,
    showCreatedBy,
    showCreatedOn,
    showDeadline,
  } = displayData;
  const isTaskCompleted = task.completed
    ? task.completed
    : task.closed
    ? true
    : false;
  const taskCompleteDate = task.completed ? new Date(task.completed) : null;
  const taskClosedDate = task.closed ? new Date(task.closed) : null;
  const deadlineDate = new Date(task.deadline);
  const taskCreated = new Date(task.created);
  const isTaskPastDeadline = deadlineDate < new Date();
  const isDeadlineClose =
    deadlineDate < new Date(Date.now() + 1000 * 60 * 60 * 24 * 3);
  const taskBorderColor =
    isTaskPastDeadline && !isTaskCompleted
      ? "border-red-500"
      : "border-gray-300";
  const taskHighlightColor =
    isTaskPastDeadline && !isTaskCompleted
      ? "bg-red-50"
      : isTaskCompleted
      ? "bg-green-50"
      : "";

  const timeDiff = deadlineDate - new Date();
  return (
    <div
      key={index}
      style={{ cursor: "pointer" }}
      className={`px-4 py-3 ${
        index % 2 === 0 ? "bg-gray-50" : ""
      } ${taskBorderColor} ${taskHighlightColor}`}
    >
      {task.address && <OpenInGoogleMaps address={task.address} coords={task.coords} />}
      <div
        onClick={() => onClick(task)}
        className="flex items-center justify-between"
      >
        <div className="flex-1 mr-4">
          <div
            className="flex text-small font-medium text-black-900 justify-between"
            style={{ alignItems: "center" }}
          >
            <span
              className="task-title break-words pt-2"
              style={{ marginBottom: 0 }}
            >
              {task.task_name}
            </span>
            <div className="text-xs text-gray-500">
              {isTaskPastDeadline ? (
                <span className={!isTaskCompleted ? "text-red-500" : undefined}>
                  {timeDiffToDisplay(timeDiff)} <FormattedMessage id="ago" />
                </span>
              ) : (
                <span>{timeDiffToDisplay(timeDiff)} <FormattedMessage id="left" /></span>
              )}
            </div>
          </div>
          <div className="text-xs text-gray-500">
            {showCreatedBy && (
              <>
                <span className="font-bold"><FormattedMessage id="created_by" /></span> {task.createdby}{" "}
                |{" "}
              </>
            )}
            {showCreatedOn && (
              <>
                <span className="font-bold"><FormattedMessage id="created_on" /></span>{" "}
                {taskCreated.toLocaleString("default", {
                  dateStyle: "short",
                  timeStyle: "short",
                  hour12: false,
                })}
              </>
            )}
          </div>
          {showDeadline && (
            <div className="text-xs text-gray-500">
              <span className="font-bold"><FormattedMessage id="deadline" /></span>{" "}
              <span
                className={`font-bold ${
                  isTaskPastDeadline && !isTaskCompleted
                    ? "text-red-500"
                    : isDeadlineClose && !isTaskCompleted
                    ? "text-orange-500"
                    : "text-gray-500"
                }`}
              >
                {deadlineDate.toLocaleString("default", {
                  dateStyle: "medium",
                  timeStyle: "short",
                  hour12: true,
                })}
              </span>
            </div>
          )}
          <div className="text-xs text-gray-900 mt-1">
            {task.task_description}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {showCheckbox && (
            <div
              className={`h-6 w-6 flex items-center justify-center rounded-full border
                         ${
                           isTaskCompleted
                             ? `border-green-500 text-green-500`
                             : isTaskPastDeadline
                             ? "border-red-500"
                             : "border-gray-300"
                         } text-gray-300 mr-2`}
            >
              {isTaskCompleted ? <Check size={16} /> : <Square size={16} />}
            </div>
          )}
          {showAsignedTo && (
            <div className="text-xs text-gray-500">
              <FormattedMessage id="assigned_to" />{task.assignedto}
            </div>
          )}
        </div>
        {task && taskCompleteDate && (
          <div className="text-xs" style={{ float: "right" }}>
            <b><FormattedMessage id="finished" /></b>{" "}
            {taskCompleteDate &&
              taskCompleteDate.toLocaleString("default", {
                dateStyle: "medium",
                timeStyle: "short",
                hour12: true,
              })}
          </div>
        )}
        {task && taskClosedDate && (
          <div className="text-xs" style={{ float: "right" }}>
            <b><FormattedMessage id="closed" /></b>{" "}
            {taskClosedDate &&
              taskClosedDate.toLocaleString("default", {
                dateStyle: "medium",
                timeStyle: "short",
                hour12: true,
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskList;

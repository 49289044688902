import {
  createStyles,
  Card,
  Text,
  SimpleGrid,
  UnstyledButton,
} from "@mantine/core";
import {
  CreditCard,
  Receipt,
  CalendarPlus,
  UserPlus,
  FileDollar,
  FriendsOff,
  FileSpreadsheet
} from "tabler-icons-react";
import AddInvoiceScreen from "../Screens/Client/AddInvoiceScreen";
import AddTaskScreen from "../Screens/Client/AddTaskScreen";
import PartnerScreen from "../Screens/Client/PartnerScreen";
import hasAccess from "../functions/access";
import { getGroup } from "../functions/tokens";
import AddSourceScreen from "../Screens/User/SourceScreen";
import { useNavigate } from "react-router-dom";
import CauseScreen from "../Screens/User/CauseScreen";
import { FormattedMessage } from 'react-intl';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    paddingBottom: 7,
  },

  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: theme.radius.md,
    height: 90,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease, transform 100ms ease",
    "&:hover": {
      boxShadow: `${theme.shadows.md} !important`,
      transform: "scale(1.05)",
    },
  },
}));

export function ActionBar({ action, title }) {
  const group = getGroup();

  const data = [
    hasAccess(group, "invoices") && {
      title: <FormattedMessage id="invoices" />,
      icon: CreditCard,
      color: "violet",
      route: "/invoices",
      nav: true,
    },
    hasAccess(group, "invoices", ["view", "insert"]).length >= 2 && {
      title: <FormattedMessage id="new_invoice" />,
      icon: FileDollar,
      color: "blue",
      component: <AddInvoiceScreen />,
      route: "/invoices/new",
    },
    {
      title: <FormattedMessage id="new_task" />,
      icon: CalendarPlus,
      color: "green",
      component: <AddTaskScreen />,
      route: "/task/new",
    },
    {
      title: <FormattedMessage id="new_client" />,
      icon: UserPlus,
      color: "teal",
      component: <PartnerScreen modify={true} newEntry={true} />,
      route: "/partner/new",
    },
    group >= 0 &&
      group <= 9 && {
        title: <FormattedMessage id="add_source" />,
        icon: Receipt,
        color: "orange",
        component: <AddSourceScreen modify={true} newEntry={true} />,
      },
    group >= 0 &&
      group <= 9 && {
        title: <FormattedMessage id="closing_reasons" />,
        icon: FriendsOff,
        color: "pink",
        component: <CauseScreen />,
      },
    // group >= 0 &&
    //   group <= 9 && {
    //     title: "Dokumenti",
    //     icon: FileSpreadsheet,
    //     color: "pink",
    //     component: <CauseScreen />,
    //   },
  ].filter(Boolean);

  const { classes, theme } = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <Text className={classes.title}>{title ? title : <FormattedMessage id="actions_title" />}</Text>
      <Card withBorder radius="md" className={classes.card}>
        <SimpleGrid cols={2} mt="md">
          {data &&
            data.map((item) => (
              <UnstyledButton
                key={item.title}
                className={classes.item}
                onClick={() => {
                  item.nav ? navigate(item.route) : action(item);
                }}
              >
                <item.icon color={theme.colors[item.color][6]} size={32} />
                <Text size="xs" mt={7}>
                  {item.title}
                </Text>
              </UnstyledButton>
            ))}
        </SimpleGrid>
      </Card>
    </>
  );
}
